import { core } from "@otto-ec/global-resources/core";
import { OnCarouselCreated, OnCarouselStageChanged } from "../../types/Assets";
import { ActionName, Feature, TrackingLabels } from "../../types/Tracking";
import { eventQBus } from "../../types/EventQBus";
import { event } from "@otto-ec/global-resources/event";
import { RatingSliderRequestParams } from "./RatingSliderRequestParams";
import tracking from "../../tracking/Tracking";

/**
 *
 *
 *
 */

export default abstract class Slider {
  protected sliderContainer: HTMLElement | null = null;

  protected sliderItemClassName = "reptile_slider__item";
  protected containerClassName = "reptile_slider__container";
  protected containerClass = "." + this.containerClassName;

  constructor() {}

  get avContent(): HTMLElement | null {
    return document.getElementById("avContent");
  }

  public registerSlider() {
    this.fetchSlider();
    eventQBus.on("ftfind.tilelist.loaded", () => {
      this.fetchSlider();
    });
  }

  public fetchSlider() {
    if (!this.avContent) {
      return;
    }

    const container = document.querySelector(this.containerClass) as HTMLElement;
    const rule = this.avContent.dataset.rule;
    const ratingSliderRequestParams = new RatingSliderRequestParams(this.avContent);

    if (container && rule && this.isType(container.dataset.type)) {
      this.sliderContainer = container;
      const ruleParam = core.serialize(ratingSliderRequestParams.requestParams);

      fetch(`/everglades/${this.getPathSegment()}?${ruleParam}`)
        .then((response) => {
          if (response.status === 200) {
            return response.text();
          }
        })
        .then((html) => {
          if (html) {
            container.classList.add("reptile_slider--initialized");
            container.innerHTML = html;
            this.trackBaseTrackingLabels();
            this.initCarousel();
          }
        });
    }
  }

  protected initCarousel(): void {
    if (this.sliderContainer) {
      eventQBus.on("pattern.carousel.stage.changed", this.onStageChanged.bind(this));
      eventQBus.on("pattern.carousel.mounted", this.trackInitialFeature.bind(this));
      eventQBus.emit("pattern.carousel.init", this.containerClass + ">.js_pl_carousel");
      event.delegate(this.sliderContainer, "click", ".product a", this.trackClickEvent.bind(this));
      this.restoreSlidePosition();
    }
  }

  protected trackBaseTrackingLabels(): void {
    const element = document.querySelector(this.containerClass + " > .js_pl_carousel") as HTMLElement;
    tracking.addToPageImpression(this.getBaseTrackingLabel(+(element.dataset.count || 0)));
  }

  protected onStageChanged(event: OnCarouselStageChanged): void {
    this.storeLastSlidePosition(event.activeSlide);
    this.trackInteraction(event);
  }

  protected trackInitialFeature(event: OnCarouselCreated): void {
    if (event.element.classList.contains("reptile_slider")) {
      const features: Feature[] = this.prepareFeatureTracking(event.currentSlides);
      tracking.addFeaturesToPageImpression(features);
    }
  }

  protected trackInteraction(event: OnCarouselStageChanged): void {
    if (event.element.classList.contains("reptile_slider")) {
      const features: Feature[] = this.prepareFeatureTracking(event.currentSlides);
      tracking.submitAction(
        {},
        {
          name: (event.scrollingRight ? "next" : "previous") as ActionName,
          features: features,
        },
      );
    }
  }

  protected storeLastSlidePosition(activeSlide: number) {
    if (this.sliderContainer) {
      this.sliderContainer.dataset.lastActiveSlide = "" + activeSlide;
    }
  }

  protected restoreSlidePosition() {
    if (this.sliderContainer && this.sliderContainer.dataset.lastActiveSlide) {
      const slider = document.getElementsByClassName("reptile_slider");
      if (slider.length === 1) {
        eventQBus.emit("pattern.carousel.move", {
          element: slider[0] as HTMLElement,
          index: +this.sliderContainer.dataset.lastActiveSlide,
        });
      }
    }
  }

  protected trackClickEvent(ev: MouseEvent): void {
    const target = ev.target as Element;
    const tile = target.closest(".reptile_slider__item") as HTMLElement;
    const position = tile.dataset.position;
    if (position && this.avContent) {
      const features: Feature[] = this.prepareFeatureTracking([+position - 1]);
      tracking.submitAction({}, { name: "click" as ActionName, features: features });
      tracking.trackOnNextPageImpression(this.getClickTrackingLabel(+position, ev));
    }
  }

  protected abstract getPathSegment(): string;

  protected abstract isType(type: string | undefined): boolean;

  protected abstract getClickTrackingLabel(position: number, mouseEvent: MouseEvent): TrackingLabels;

  protected abstract getBaseTrackingLabel(count: number): TrackingLabels;

  protected abstract prepareFeatureTracking(currentSlides: number[]): Feature[];
}
