import { Action, EventMergeId, Feature, MiniAction, TrackingLabels } from "../types/Tracking";
import { type DataContainer, tracking } from "@otto-ec/tracking-bct";
import { misc } from "@otto-ec/global-resources/misc";

class Tracking {
  static convertLabelsToDataContainer(labels: Partial<TrackingLabels>): DataContainer {
    const dataContainer: DataContainer = {};

    Object.keys(labels).forEach((key) => {
      const value = labels[key as keyof TrackingLabels];
      if (value !== undefined) {
        dataContainer[key] = Array.isArray(value) ? value : [value];
      }
    });

    return dataContainer;
  }

  replaceContext(id: string, url: string): void {
    tracking.replaceContext(id, url, {});
  }

  submitEvent(labels: Partial<TrackingLabels>): void {
    tracking.submitEvent(Tracking.convertLabelsToDataContainer(labels));
  }

  addToEvent(labels: Partial<TrackingLabels>, eventMergeId: EventMergeId): void {
    tracking.submitEventMerge(eventMergeId, Tracking.convertLabelsToDataContainer(labels));
  }

  addToPageImpression(labels: Partial<TrackingLabels>): void {
    tracking.submitMerge(Tracking.convertLabelsToDataContainer(labels));
  }

  addFeaturesToPageImpression(features: Feature[]): void {
    tracking.submitMerge({}, features);
  }

  trackOnNextPageImpression(labels: Partial<TrackingLabels>): void {
    tracking.trackOnNextPageImpression(Tracking.convertLabelsToDataContainer(labels));
  }

  submitMoveAction(labels: Partial<TrackingLabels>, action: Action): void {
    tracking.submitMove(Tracking.convertLabelsToDataContainer(labels), action);
  }

  addActionToEvent(action: Action, eventMergeId: EventMergeId): void {
    tracking.submitEventMerge(eventMergeId, {}, action);
  }

  submitAction(labels: Partial<TrackingLabels>, action: Action): void {
    tracking.submitEvent(Tracking.convertLabelsToDataContainer(labels), action);
  }

  submitMiniActions(miniActions: MiniAction[]): void {
    tracking.submitMiniAction(miniActions);
  }

  async getPageMergeId(): Promise<string> {
    return tracking.getPageMergeId().then((mergeId) => mergeId ?? misc.guid());
  }
}

export default new Tracking();
