import { Feature } from "../types/Tracking";
import { SimilarSheetElements } from "./SimilarSheetElements";
import tracking from "../tracking/Tracking";
import { resolveLinkType } from "../tilelist/ProductTileClickHandling";

export type SimilarProductsSheetTrackingLabels = Partial<{
  san_ListLayer: string;
  ts_LayerEvent: string;
  san_ProductType: string;
  san_Interaction: string;
  "wk.san_ProductType": string;
  "wk.san_ProductClickListType": string;
  "wk.san_ProductLinkType": string;
  "wk.san_ComparablesSheetPosition": string;
}>;

export function trackSimilarSheetOpen(): void {
  const selectedContent = document.querySelector(SimilarSheetElements.SIMILAR_SHEET_TILE_SELECTOR) as HTMLElement;
  const tiles: HTMLElement[] = Array.from(document.querySelectorAll(SimilarSheetElements.PRODUCT_SELECTOR));
  const similarProductFeatures = tiles.map((tile, index) => similarSheetFeature(tile, index + 1));
  const openEventLabels = {
    san_ListLayer: "comparables",
    ts_LayerEvent: "open",
  };
  const parentFeature = <Feature>{
    id: "san_comparables_sheet",
    name: "Comparables-Sheet",
    status: "loaded",
    labels: {
      san_FilledSlotsComparablesSheet: tiles.length,
    },
  };

  tracking.submitAction(openEventLabels, {
    name: "open",
    features: [parentFeature, similarSheetFeature(selectedContent, 0), ...similarProductFeatures],
  });
}

export function trackClickEvent(event: MouseEvent, position: number): void {
  if (!SimilarSheetElements.AV_CONTENT) {
    return;
  }
  const clickEventLabels = {
    san_Interaction: "comparables_sheet_tile",
    "wk.san_ProductType": "comparables_sheet",
    "wk.san_ProductClickListType": SimilarSheetElements.AV_CONTENT.dataset.pt,
    "wk.san_ProductLinkType": position === 0 ? "selected_content_button" : resolveLinkType(event),
    "wk.san_ComparablesSheetPosition": position,
  };
  tracking.trackOnNextPageImpression(clickEventLabels);
}

function similarSheetFeature(tile: HTMLElement, index: number) {
  if (!tile.dataset.variationId) {
    throw new Error("SimilarProductSheet Feature: Tile is missing variationId");
  }
  return <Feature>{
    id: "san_comparables_tile_" + index,
    name: "Comparables-Sheet-Tile",
    status: "loaded",
    parentId: "san_comparables_sheet",
    position: index,
    variationId: tile.dataset.variationId,
    labels: {
      san_ProductType: "comparables_sheet",
    },
  };
}
