export type DummyExperimentVariation = "StatusQuo" | "DummyVar1" | "DummyVar2"; /*                                               */
export type ReplaceColorTilesExperimentVariation = "StatusQuo" | "Amount" | "Smaller"; /*                                               */

export type Experiments = {
  d666?: DummyExperimentVariation;
  e3412?: ReplaceColorTilesExperimentVariation;
};

const EXPERIMENTS_ELEMENT_ID = "reptile-everglades-experiments";

export default class ExperimentsProvider {
  private experiments: { [T in keyof Experiments]?: Experiments[T] } = {};

  constructor() {
    const data = document.getElementById(EXPERIMENTS_ELEMENT_ID)?.dataset;
    if (data) {
      for (const key in data) {
        const variation = data[key];
        if (variation) {
          (<any>this.experiments)[key.toLowerCase()] = variation;
        }
      }
    }
  }

  public getActiveVariation<T extends keyof Experiments>(
    exp: T,
    defaultVariation?: Experiments[T],
  ): Experiments[T] | undefined {
    return this.experiments ? this.experiments[exp] || defaultVariation : defaultVariation;
  }
}

export const experimentsProvider = new ExperimentsProvider();
