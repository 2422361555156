/*                                    */
import { eventQBus } from "../types/EventQBus";
import { DresonRuleResolvedEvent, Page } from "../types/Tilelist";
import { TrackingLabels } from "../types/Tracking";
import * as Core from "../utils/Core";
import * as Global from "../utils/Global";

let initialized = false;

/**
 *
 *
 *
 */
function currentRule() {
  const tileListBracket = document.getElementById("reptile-tilelist-bracket");
  return (tileListBracket && tileListBracket.dataset.rule) || "";
}

/**
 *
 *
 *
 */
function currentPageNumber() {
  const currentPageLink = document.getElementById("san_pagingCurrentPage");
  return (currentPageLink && currentPageLink.dataset.pageNumber) || "1";
}

/**
 *
 *
 *
 */
function currentPage() {
  const searchResult = document.getElementById("reptile-search-result");
  return JSON.parse((searchResult && searchResult.dataset.page) || "{}");
}

/**
 *
 *
 *
 *
 *
 *
 */
function checkpointData(rule: string, page: Page, tsLink?: TrackingLabels) {
  return {
    rule: rule,
    page: page,
    tsLink: {
      ...tsLink,
      san_Interaction: "browser_navigation",
    },
    historic: true,
  };
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
function createCheckpoint(event: DresonRuleResolvedEvent) {
  const { rule, page, tsLink, historic, redirect } = event,
    cleanedPage = Core.clean(page);

  if (historic) return;

  eventQBus.emit("ftfind.history.push", {
    topic: "ftfind.dresonRule.resolve",
    data: checkpointData(rule, page, tsLink),
    url: redirect || Global.pagedUrl(cleanedPage, window.location.pathname, window.location.search),
  });
}

/**
 *
 */
export function registerTileListHistory() {
  if (initialized) {
    return;
  }
  initialized = true;

  const currentCheckpointData = checkpointData(currentRule(), currentPage(), {
    san_NaviPaging: currentPageNumber(),
    ts_RemoveLabels: "wk.nav_UnfilteredSelectionRule",
  });

  eventQBus.emit("ftfind.history.merge", {
    topic: "ftfind.dresonRule.resolve",
    data: currentCheckpointData,
  });
  eventQBus.on("ftfind.dresonRule.resolved", createCheckpoint);
}
