export class SimilarSheetElements {
  /*                                                                 */
  private constructor() {}

  public static SHEET_ID = "reptile_similar-products-sheet";
  public static SELECTED_CONTENT_SELECTOR = ".reptile_similar-products-sheet_selectedContent";
  public static SIMILAR_SHEET_TILE_SELECTOR = ".reptile_similarSheetTile";
  public static SIMILAR_PRODUCTS_CONTAINER_SELECTOR = ".reptile_similar-container";
  public static PRODUCT_SELECTOR = "#reptile_similar-tilelist article.product";
  public static IMAGE_BUTTON_SELECTOR = ".reptile_similarSheetTile__imageButton";

  static get SIMILAR_PRODUCTS_CONTAINER(): HTMLElement | null {
    return document.querySelector(this.SIMILAR_PRODUCTS_CONTAINER_SELECTOR);
  }

  static get SHEET(): HTMLElement | null {
    return document.getElementById(this.SHEET_ID);
  }

  static get SELECTED_CONTENT_CONTAINER(): HTMLElement | null {
    return document.querySelector(this.SELECTED_CONTENT_SELECTOR);
  }

  static get SELECTED_CONTENT_IMAGE_BUTTON(): HTMLElement | null {
    return document.querySelector(this.IMAGE_BUTTON_SELECTOR);
  }

  static get AV_CONTENT(): HTMLElement | null {
    return document.getElementById("avContent");
  }

  static get SIMILAR_PRODUCT_TILES(): NodeListOf<HTMLElement> {
    return document.querySelectorAll<HTMLElement>(SimilarSheetElements.PRODUCT_SELECTOR);
  }

  static get SELECTED_CONTENT_AND_TILES(): (HTMLElement | null)[] {
    return [SimilarSheetElements.SELECTED_CONTENT_IMAGE_BUTTON, ...SimilarSheetElements.SIMILAR_PRODUCT_TILES];
  }
}
